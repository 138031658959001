import {
  takeLatest, put, select
} from 'redux-saga/effects'
import moment from 'moment/moment'
import request from '@/helpers/axios'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import { GET_PASSPORTIZATION_TABLE_FILE_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_PASSPORTIZATION_TABLE_FILE } from '@/store/actions/objectsGroups'
import {
  errorPassportizationTableFile,
  successPassportizationTableFile
} from '@/store/actions/objectsGroups/getPassportizationTableFile'
import { getPassportizationTableParameters } from '@/store/selectors/objectsGroups'

function* getPassportizationTableFileSaga({ payload }) {
    const { groupId, name, fields, transponse } = payload
    const tableParameters = yield select(getPassportizationTableParameters)
    const toast = createNotifications()
    try {
      const response = yield request({
        url: GET_PASSPORTIZATION_TABLE_FILE_URL({
          params: {
            groupId,
            fields,
            pageNumber: tableParameters.page - 1,
            pageSize: tableParameters.perPage,
            search: tableParameters.requestQuery || '',
            sort: ['id: desc'],
            isTransposed: transponse,
          },
        }),
        method: 'get',
        options: {
          responseType: 'blob',
        },
      })
      const date = moment(new Date()).format("DD.MM.YYYY")
      downloadFileFromBlob({
        blob: response.data,
        fileName: `Таблица_с_данными_группы_объектов"${name}","${date}".xlsx`,
      })
      yield put(successPassportizationTableFile())
    } catch (error) {
        yield put(errorPassportizationTableFile(error))
        toast({
          title: 'Ошибка операции!',
          description: 'Невозможно выгрузить файл. \nПовторите попытку позже.',
          type: 'error',
        })
    }
}

export default function* root() {
  yield takeLatest(GET_PASSPORTIZATION_TABLE_FILE.REQUEST, getPassportizationTableFileSaga)
}
