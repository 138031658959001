import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_COMPOSITION_NODE_ENDPOINT } from '@/constants/apiRoutes'
import { successGetCompositionNode } from '@/store/actions/passportization/getCompositionNode'
import {
  GET_COMPOSITION_NODE,
} from '@/store/actions/passportization'
import { getGlobalFilters, getSelectedNode } from '@/store/selectors/appSettings'
import {
  SET_GLOBAL_FILTER_VALUES,
  RESET_GLOBAL_FILTER_VALUES,
} from '@/store/actions/appSettings'


function* getCompositionNode({ payload }) {
  const {id, treeNodeType} = yield select(getSelectedNode)
  try {
    const {NO_PROBLEM, WARNING, ERROR, UNDEFINED, ...filters} = yield select(getGlobalFilters)
    const statuses = {
      NO_PROBLEM,
      WARNING,
      ERROR,
      UNDEFINED,
    }
    const statusesTrue = Object.keys(statuses).filter((status) => statuses[status])
    const telemetryStatuses = statusesTrue.length === Object.keys(statuses).length ? [] : statusesTrue
    const response = yield request({
      url: GET_COMPOSITION_NODE_ENDPOINT({
        params: {
          id,
          isRoot: id === 1,
          treeNodeType,
          telemetryStatuses,
          ...filters,
        },
    }),
      method: 'get',
    })
    yield put(successGetCompositionNode(response.data))

  } catch (error) {

  }
}

export default function* root() {
  yield takeLatest(GET_COMPOSITION_NODE.REQUEST, getCompositionNode)
  yield takeLatest(SET_GLOBAL_FILTER_VALUES, getCompositionNode)
  yield takeLatest(RESET_GLOBAL_FILTER_VALUES, getCompositionNode)
}
