import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import pt from 'prop-types'
import useReduxTable from '@/hooks/useReduxTable'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import { FieldsContainer } from '@/forms/ObjectsGroups/CreateEditObjectsGroupForm/styles'
import {
  ChangeButton,
  CloseIconContainer,
  FieldValueWrapper,
  MessageContainer,
  SubmitButton,
  InputAndLabelContainer,
  IconContainer,
  TableWrapper
} from './styles'
import AlarmsIcons from '@/components/icons/alarms'
import Loader from '@/components/blocks/Loader'
import UsersIcons from '@/components/icons/users'
import { Form, Formik } from 'formik'
import get from 'lodash/get'
import TextField from '@/components/fields/TextField'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import CoreIcons from '@/components/icons/core'
import SelectField from '@/components/fields/SelectField'
import OBJECTS_GROUPS_PASSPORTIZATION from '@/constants/forms/objectsGroups'
import REQUEST_STATUSES from '@/constants/requests'
import DutyTable from '@/components/blocks/DutyTable'
import { PASSPORTIZATION_TABLE }  from '@/store/actions/objectsGroups'
import { getPassportizationTableParameters } from '@/store/selectors/objectsGroups'
import { exportOptions } from '@/constants/tablesConfig/objectsGroups'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'

const PassportizationTab = ({
  intl,
  fieldOptions,
  fieldOptionsStatus,
  groupId,
  selectedObjectsGroup,
  exportFileStatus,
  tableData,
  tableFields,
  loadingTable,
  filterValues,
  userData,
  isMenuSideBarPinned,
  isMenuOpen,
  isTreeOpen,

  requestGetOptions,
  requestSetData,
  requestGetTable,
  requestDownloadTableFile,
  setTableFilterValues,
  toggleTransponseTable,
  transponseTable,
}) => {
  const [data, setData] = useState([])
  const [tableFieldsData, setTableFieldsData] = useState([])
  const [canAddField, setCanAddField] = useState(false)
  const [tableDataRequested, setTableDataRequested] = useState(false)
  const tableProps = useReduxTable(PASSPORTIZATION_TABLE, getPassportizationTableParameters)
  const  descriptions  = transponseTable ? [] : filterValues?.descriptions
  useEffect(() => {
    if (groupId) {
      requestGetOptions({ groupId, intl })
    }
  }, [requestGetOptions, groupId, intl])

  useEffect(() => {
    if (groupId && fieldOptions.length && !tableDataRequested) {
      requestGetTable({ groupId })
    }
  }, [requestGetTable, groupId, fieldOptions, tableDataRequested])

  useEffect(() => {
    setData([{
      id: 0,
      field: null,
      value: null,
      saved: false,
      options: fieldOptions
    }])
  }, [fieldOptions])

  useEffect(() => {
    if (!groupId) {
      setData([])
      setTableFilterValues({ descriptions: [] })
    }
  }, [groupId, setTableFilterValues])

  useEffect(() => {
    setTableFieldsData(descriptions.length ? tableData.filter(item => descriptions.some(field => field.value === item.value)) : tableData)
  }, [tableData, descriptions])

  const handleSetData = useCallback(() => {
    setData([])
    requestSetData({groupId, fields: data})
    setTimeout(() => {
      setData([{
        id: 0,
        field: null,
        value: null,
        saved: false,
        options: fieldOptions
      }])
      setCanAddField(false)
    })
  }, [requestSetData, groupId, data, fieldOptions])

  const handleChangeField = useCallback(() => {
    const lastField = data.at(-1)
    const formattedData = [
      ...data,
      {
        id: data.length,
        field: null,
        value: null,
        saved: false,
        options: lastField.options.filter(item => item.value !== lastField.field)
      }
    ]
    setData(formattedData)
    setCanAddField(false)
  }, [data])

  const handleDeleteField = useCallback((id) => () => {
    setData([])
    setTimeout(() => {
      const formattedData = data.filter(item => item.id !== id)
      const dataToSave = formattedData.map((item, index) => {
        const getOptions = () => {
          if (item.id < id) {
            return item.options
          }
          const optionToAdd = data[id].options.filter(option => option.value === data[id].field)[0]
          const updatedOptions = data[item.id - 1].options.filter(option => option.value !== data[item.id - 1].field)
          return [optionToAdd, ...updatedOptions]
        }

        return {
          ...item,
          id: index,
          options: getOptions()
        }
      })
      dataToSave.at(-1).saved = false
      setData(dataToSave)
      setCanAddField(false)
    })
  }, [data])

  const setFieldToNull = (selector, setFieldValue) => () => {
    setFieldValue(selector, '')
  }

  const handleSave = useCallback((item) => {
    let formattedData
    if (data.length === 1) {
      formattedData = [
        {
          id: 0,
          field: item.field,
          value: item.value,
          saved: true,
          options: fieldOptions
        }
      ]
    } else {
      formattedData = data
      formattedData[item.id] =
        {
         id: item.id,
         field: item.field,
         value: item.value,
         saved: true,
         options: item.options
        }
    }
    setCanAddField(true)
    setData(formattedData)
  }, [data, fieldOptions])

  const handleRequestTableData = () => {
    requestGetTable({ groupId })
    setTableDataRequested(false)
  }

  const handleRequestTableDataTransponse = () => {
    toggleTransponseTable(!transponseTable)
    requestGetTable({ groupId })
    setTableDataRequested(false)
  }
  const handleRequestTableFile = useCallback(() => {
    requestDownloadTableFile({
      groupId,
      name: selectedObjectsGroup.name,
      fields: filterValues?.descriptions.length
        ? filterValues.descriptions.map(field => field.value)
        : (transponseTable ? tableFieldsData.map(field => field.value) : tableData.map(field => field.value)),
    })
  }, [requestDownloadTableFile, groupId, selectedObjectsGroup, filterValues, tableData, transponseTable, tableFieldsData])

  const filterTableData = (fields) => {
    if (!fields.length) {
      setTableFieldsData(tableData)
      setTableFilterValues({ descriptions: [] })
      return
    }
    setTableFilterValues({ descriptions: fields })
    setTableFieldsData(tableData.filter(item => fields.some(field => field.value === item.value)))
  }

  const isHasPermission = userData.permissions.applicationModules
    .filter((module) => module.applicationId === ID_BY_APPLICATIONS.OBJECTS_GROUPS)
    .some((section) => section.code === 'GROUP_PASSPORTIZATION_EDIT')

  return (
    <>
      {!fieldOptions.length && fieldOptionsStatus !== REQUEST_STATUSES.IDLE
        ? <Loader center />
        : isHasPermission && (
          <FieldsContainer>
            <MessageContainer>
               <AlarmsIcons.WarningIcon color={'#0C4BAD'}/>
               <Lang id={`objectsGroups.passportization.warning`}/>
            </MessageContainer>
            {data.map(item => {
              return (
                <Formik
                  onSubmit={handleSave}
                  initialValues={item}
                  render={({
                    touched, errors, values, setFieldValue, handleSubmit, isValid
                  }) => {
                    const {
                      id,
                      saved,
                      options,
                    } = item

                    const getFormat = (field) => {
                      switch (field) {
                        case 'WORKING_VOLTAGE_RANGE':
                          case 'MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW':
                            case 'PRESSURE_MEASUREMENT_RANGE':
                              return 'range'
                        case 'WORKING_TEMPERATURE_RANGE':
                          case 'TEMPERATURE_MEASUREMENT_RANGE':
                            case 'MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN':
                              case 'LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT':
                                return 'temperatureRange'
                        default:
                          return 'text'
                      }
                    }

                    return (
                      <Form id={`PassportizationTabForm-${id}`} name={`PassportizationTabForm-${id}`} onSubmit={handleSubmit}>
                        <InputAndLabelContainer>
                          <FieldValueWrapper>
                            {!!id &&
                              <CloseIconContainer onClick={handleDeleteField(id)}>
                                <UsersIcons.CloseIcon/>
                              </CloseIconContainer>
                            }
                            <LabelWithIcon
                              title={<Lang id={`objectsGroups.passportization.labelField`}/>}
                            />
                            <SelectField
                              name={OBJECTS_GROUPS_PASSPORTIZATION.FIELD}
                              withSearch
                              placeholder={<Lang id={`objectsGroups.passportization.placeholder`}/>}
                              options={options}
                              light
                              disabled={saved}
                            />
                            {get(values, OBJECTS_GROUPS_PASSPORTIZATION.FIELD, null) &&
                              <>
                                <LabelWithIcon
                                  isError={(touched[OBJECTS_GROUPS_PASSPORTIZATION.VALUE] && errors[OBJECTS_GROUPS_PASSPORTIZATION.VALUE])}
                                  title={<Lang id={`objectsGroups.passportization.labelValue`}/>}
                                />
                                <TextField
                                  error={(touched[OBJECTS_GROUPS_PASSPORTIZATION.VALUE] && errors[OBJECTS_GROUPS_PASSPORTIZATION.VALUE])}
                                  name={OBJECTS_GROUPS_PASSPORTIZATION.VALUE}
                                  mask={values.field.includes('DATE') ? '99.99.9999' : null}
                                  fieldProps={{
                                    autoComplete: 'off',
                                    format: getFormat(values.field),
                                    pattern: values.field === 'LATITUDE' || values.field === 'LONGITUDE' ? '[0-9.+-]' : null,
                                    disabled: saved
                                }}
                                  controls={(
                                    <>
                                      {get(values, OBJECTS_GROUPS_PASSPORTIZATION.VALUE, null) && !saved &&
                                        (
                                         <PortalTooltip
                                           title={(<Lang id="tooltip.erase"/>)}
                                           renderChildren={(
                                             wrapperRef,
                                             onMouseEnterHandler,
                                             onMouseLeaveHandler,
                                           ) => (
                                             <IconContainer
                                               type="cross"
                                               onClick={setFieldToNull(OBJECTS_GROUPS_PASSPORTIZATION.VALUE, setFieldValue)}
                                               ref={wrapperRef}
                                               onMouseEnter={onMouseEnterHandler}
                                               onMouseLeave={onMouseLeaveHandler}
                                             >
                                               <CoreIcons.EraserIcon/>
                                             </IconContainer>
                                           )}
                                         />
                                        )
                                      }
                                    </>
                                  )}
                                />
                              </>
                            }
                            {get(values, OBJECTS_GROUPS_PASSPORTIZATION.VALUE, null) && !saved &&
                              <SubmitButton onClick={handleSubmit}>
                                <Lang id={`objectsGroups.passportization.save`}/>
                              </SubmitButton>
                            }
                          </FieldValueWrapper>
                        </InputAndLabelContainer>
                      </Form>
                    )
                  }}
                />
              )
            }
            )}
            {canAddField &&
              <ChangeButton onClick={handleChangeField}>
                <Lang id={`objectsGroups.passportization.change`}/>
                <UsersIcons.PlusIcon/>
              </ChangeButton>
            }
            {canAddField &&
              <SubmitButton onClick={handleSetData}>
                <Lang id={`objectsGroups.passportization.apply`}/>
              </SubmitButton>
            }
          </FieldsContainer>
        )
      }
      <TableWrapper small={!(isMenuSideBarPinned || isMenuOpen)} isTreeOpen={isTreeOpen}>
        <DutyTable
          {...tableProps}
          hideSelectFields
          fields={tableFields}
          getTableData={handleRequestTableData}
          data={tableFieldsData}
          isDataLoading={loadingTable}
          exportOptions={exportOptions}
          onFileSelect={handleRequestTableFile}
          fileStatus={exportFileStatus}
          fieldOptions={{passportField: fieldOptions}}
          handleFilterColumn={filterTableData}
          descriptions={descriptions || []}
          withFooter
          hideSortButtons
          requestTableDataTransponse={handleRequestTableDataTransponse}
          withTranspose
        />
      </TableWrapper>
    </>
  )
}

PassportizationTab.defaultProps = {
  fieldOptions: [],
  selectedObjectsGroup: {},
  fieldOptionsStatus: '',
  groupId: null,

  requestGetOptions: noop,
  requestSetData: noop,
  requestGetTable: noop,
}
PassportizationTab.propTypes = {
  fieldOptions: pt.array,
  selectedObjectsGroup: pt.objectOf(pt.object),
  fieldOptionsStatus: pt.string,
  groupId: pt.number,

  requestGetOptions: pt.func,
  requestSetData: pt.func,
  requestGetTable: pt.func,
}

export default PassportizationTab

