import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_PASSPORTIZATION_TABLE_URL,
} from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import {
  GET_PASSPORTIZATION_TABLE,
  PASSPORTIZATION_TABLE,
  setLoading,
} from '@/store/actions/objectsGroups'
import {
  getPassportizationOptions,
  getPassportizationTableParameters,
  getObjectsGroupsSelectedNode,
  getTransponseTable,
} from '@/store/selectors/objectsGroups'
import {
  errorGetPassportizationTable,
  successGetPassportizationTable
} from '@/store/actions/objectsGroups/getPassportizationTable'


function* getPassportizationTable({ payload }) {
  try {
    const { groupId } = payload
    const options = yield select(getPassportizationOptions)
    const transponse = yield select(getTransponseTable)

    const tableParameters = yield select(getPassportizationTableParameters)
    const selectedNode = yield select(getObjectsGroupsSelectedNode)
    yield put(setLoading(true))
      const response = yield request({
        url: GET_PASSPORTIZATION_TABLE_URL({
          params: {
            groupId: groupId || selectedNode.id,
            pageNumber: tableParameters.page -1,
            pageSize: tableParameters.perPage,
            search: tableParameters.requestQuery || '',
          }
        }
        ),
        method: 'get',
      })
      const formattedOptions = options.map(option => option.value)
      const formattedData = formattedOptions.map(field => {
        return response.data.content.reduce((acc, item) => {
          const fieldInObjectFields = item.objectFields && item.objectFields.find(el => el.fieldName === field)
          return {
            ...acc,
            [`object-${item.id}`]: fieldInObjectFields ? fieldInObjectFields.value : '-'
          }
        }, { passportField: <Lang id={`passport.fields.${field}`}/>, value: field })
      })

      const fieldsTranspose = formattedOptions.map((el) => {
        return {
          id: el,
          name: <Lang id={`passport.fields.${el}`}/>,
        }
      })

      const formattedFieldsTranspose = [
        {
          id: 'objectName',
          name: 'Наименование объекта',
          type: 'string',
        },
        ...fieldsTranspose
      ]

      const formattedDataTransponse = response.data.content.reduce((acc, el) => {
       const field =  el.objectFields.reduce((a, item) => {
        return {
          ...a,
          [item.fieldName]: item.value
        }
        }, {})
        
        return [
          ...acc, 
          {
            objectName: el.name, 
            ...field
          }
        ]
      }, [])

      const fields = response.data.content.map(item => {
        return {
          id: `object-${item.id}`,
          name: item.name,
        }
      })

      const formattedFields = [
        {
          id: 'passportField',
          name: 'Поле паспорта',
          type: 'string',
        },
        ...fields
      ]

      const { pageable } = response.data

      yield put(successGetPassportizationTable({
        data: transponse ? formattedDataTransponse : formattedData,
        tableFields: transponse? formattedFieldsTranspose : formattedFields,
        totalElements: pageable.totalElements,
        totalPages: pageable.totalPages,
        page: tableParameters.page,
        perPage: tableParameters.perPage,
        requestQuery: tableParameters.requestQuery
      }))
      yield put(setLoading(false))

  } catch (error) {
    yield put(errorGetPassportizationTable(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные для таблицы.\nПовторите попытку позже.',
      type: 'error',
    })
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_PASSPORTIZATION_TABLE.REQUEST, getPassportizationTable)
  yield takeLatest(PASSPORTIZATION_TABLE.SORT, getPassportizationTable)
  yield takeLatest(PASSPORTIZATION_TABLE.PER_PAGE, getPassportizationTable)
  yield takeLatest(PASSPORTIZATION_TABLE.PAGE, getPassportizationTable)
  yield takeLatest(PASSPORTIZATION_TABLE.REQUEST_QUERY, getPassportizationTable)

}