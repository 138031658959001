import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { ALARM_MANAGER_SET_READ_ALARM_ENDPOINT } from '@/constants/apiRoutes'
import { SET_SELECTED_ELEMENT } from '@/store/actions/alarmManager'
// import {
//   requestAlarmManagerTable,
// } from '@/store/actions/alarmManager/getTable'
import {
  requestGetAlarmsCount,
} from '@/store/actions/alarmManager/getAlarmsCount'
import createNotifications from "@/helpers/notification";
import { requestGetCard } from '@/store/actions/installation/getCard';

function* setEventRead({ payload }) {
  try {
    if (payload && payload.id) {
      yield request({
        url: ALARM_MANAGER_SET_READ_ALARM_ENDPOINT(payload.id),
        method: 'put',
      })
      yield put(requestGetCard({id: payload.installationId}))
      yield put(requestGetAlarmsCount())
    }
  } catch (error) {
    console.log('TCL: function*setEventRead -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно отметить неисправность как прочитанную.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(SET_SELECTED_ELEMENT, setEventRead)
}
