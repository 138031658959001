import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  .svgFill {
    fill: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  }
  transform: translateY(-50%);

  @media (max-width: 910px) {
    .svgFill{
      display: none;
    }
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: 7px;
  right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  .svgFill {
    fill: ${({ theme }) => theme.colors.colors.default};
    width: 14px;
    height: 14px;
  }
  cursor: pointer;

  @media (max-width: 910px) {
    .svgFill{
      display: block;
    }
  }

`

export const Circle = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 0px;
  left: calc(50% - 40px);
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  display: flex;
  justify-content: center;
  align-items: center;
  .svgFill {
    fill: ${({ theme, type }) => theme.colors.backgrounds.notification[type]
    || theme.colors.messages[type]};
  }

  @media (max-width: 910px) {
    display: none;
  }
`

export const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 90px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 910px) {
    border-radius: 20px;
    top: 0;
  }
`

export const PrimaryTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  padding: 10px;
`

export const SecondaryTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.colors.dim};
  text-align: center;
`

export const ButtomsContainer = styled.div`
  padding: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  button {
    margin: 0px 3px;
    padding: 0px 36px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ButtonWrapperText = styled.div`
  padding-bottom: 10px;
`
