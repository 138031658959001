import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getPassportizationOptions,
  getPassportizationOptionsStatus,
  getPassportizationTableData,
  getPassportizationTableDownloadStatus,
  getPassportizationTableFields,
  getPassportizationTableLoading,
  getTransponseTable,
} from '@/store/selectors/objectsGroups'
import PassportizationTab from './component'
import { requestSetPassportizationData } from '@/store/actions/objectsGroups/setPassportizationData'
import { requestGetPassportizationOptions } from '@/store/actions/objectsGroups/getPassportizationOptions'
import { requestGetPassportizationTable } from '@/store/actions/objectsGroups/getPassportizationTable'
import { requestPassportizationTableFile } from '@/store/actions/objectsGroups/getPassportizationTableFile'
import { setObjectsGroupsPassportizationFilterValues } from '@/store/actions/appSettings'
import { toggleTransponseTable } from '@/store/actions/objectsGroups'
import {
  getIsMenuOpen,
  getObjectsGroupsPassportizationFilters,
  getSideBarMenuPinnedState,
  getIsTreeOpen,
} from '@/store/selectors/appSettings'

const mapStateToProps = (state) => ({
  fieldOptions: getPassportizationOptions(state),
  fieldOptionsStatus: getPassportizationOptionsStatus(state),
  tableData: getPassportizationTableData(state),
  loadingTable: getPassportizationTableLoading(state),
  exportFileStatus: getPassportizationTableDownloadStatus(state),
  tableFields: getPassportizationTableFields(state),
  filterValues: getObjectsGroupsPassportizationFilters(state),
  isMenuSideBarPinned: getSideBarMenuPinnedState(state),
  isMenuOpen: getIsMenuOpen(state),
  isTreeOpen: getIsTreeOpen(state),
  transponseTable: getTransponseTable(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestSetData: (payload) => dispatch(requestSetPassportizationData(payload)),
  requestGetOptions: (payload) => dispatch(requestGetPassportizationOptions(payload)),
  requestGetTable: (payload) => dispatch(requestGetPassportizationTable(payload)),
  requestDownloadTableFile: (payload) => dispatch(requestPassportizationTableFile(payload)),
  setTableFilterValues: (payload) => dispatch(setObjectsGroupsPassportizationFilterValues(payload)),
  toggleTransponseTable: (payload) => dispatch(toggleTransponseTable(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PassportizationTab))
