import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_OBJECT_REFERENCE_INSTALLATION_OBJECT,
} from '@/store/actions/objectReference'
import {
  GET_INSTALLATION_OBJECT_URL
} from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { successInstallationObject} from '@/store/actions/objectReference/getInstallationObject'
import { sortObjectIdentifier } from '@/helpers/sortObjectIdentifier'



function* getInstallationObjectSaga({payload}) {
  const toast = createNotifications()
  try {
    const response = yield request({
      url: GET_INSTALLATION_OBJECT_URL({
        parentId: payload.parentId,
        params: {
          registerNumber: payload.registerNumber,
        }
      }),
      method: 'get',
    })

    // const installationTypeConfig = {
    //   'UTILITY_POLE': 'Опоры',
    //   'LIGHT_FIXTURE': 'Светильники',
    //   'CONTROL_CUPBOARD': 'ШУНО',
    //   'LAMP_MODULE': 'Модуль светильника',
    // }
    // const installationType = [...new Set(response.data.map((el) => el.installationType))].map((el) => { return {name: installationTypeConfig[el], installationType: el}})
    
    // const data = installationType.map(item => {
    //   return {
    //     ...item,
    //     opened: false,
    //     children: sortObjectIdentifier(response.data.filter(child => child.installationType === item.installationType))
    //   }
    // })
    
    yield put(successInstallationObject(response.data.map((el) => {
      return {
        ...el,
        children: sortObjectIdentifier(el.children)
      }
    })))


  } catch (error) {
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECT_REFERENCE_INSTALLATION_OBJECT.REQUEST, getInstallationObjectSaga)
}
