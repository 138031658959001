import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import pt from 'prop-types'
import get from 'lodash/get'
import HeaderIcons from '@/components/icons/header'
import Button from '@/components/blocks/Button'
import Icons from '@/components/icons/users'
import localConfig from './config'
import {
  ContentContainer,
  Container,
  Circle,
  PrimaryTitle,
  SecondaryTitle,
  ButtomsContainer,
  IconContainer,
  ButtonWrapperText
} from './styles'

const GlobalPopupContent = (props) => {
  const {
    type,
    title,
    message,
    onClose,
    config,
    shoodBeFixByArtem,
    isMobile,
    handleOnSetUnpinNode,
    isSelectedPin
  } = props
  const Icon = get(config, `${type}.icon`, React.Fragment)
  return (
    <Container>
      <Circle type={type === 'editSuccess' ? 'success': type}>
        <Icon />
      </Circle>
      <HeaderIcons.PopupHeaderIcon width={isMobile ? '300' : '400'}/>
      <ContentContainer>
      <IconContainer onClick={onClose}>
        <Icons.CrossIcon/>
      </IconContainer>
        <PrimaryTitle>
          {title}
        </PrimaryTitle>
        <SecondaryTitle>
          {message}
        </SecondaryTitle>
        <ButtomsContainer>
          {!shoodBeFixByArtem
            ? (
              <>
                {get(config, `${type}.buttons`, []).map((element) => (
                  <Button
                    onClick={element.onClickSelector || noop}
                    styleType={element.statusType}
                    disabled={element.disabled}
                  >
                    {element.title}
                  </Button>
                ))}
              </>
            )
            : (
              <>
                {get(config, `${type}.buttons`, []).map((element) => (
                  <Button
                    onClick={get(props, element.onClickSelector, noop)}
                    styleType={element.statusType}
                    disabled={element.disabled}
                  >
                    {element.title}
                  </Button>
                ))}
              </>
            )}
        </ButtomsContainer>
        {isMobile && isSelectedPin &&
        <ButtonWrapperText>
          <Button styleType={'text'} onClick={handleOnSetUnpinNode}>
            <Lang id="menu.unpinNode" />
          </Button>
        </ButtonWrapperText>
        }
      </ContentContainer>
    </Container>
  )
}

GlobalPopupContent.defaultProps = {
  type: 'success',
  title: 'Внимание!',
  message: 'Вы действительно хотите\nудалить объект из системы?',
  onClose: noop,
  primaryAction: noop,
  secondaryAction: noop,
  config: localConfig,
  shoodBeFixByArtem: false,
}

GlobalPopupContent.propTypes = {
  type: pt.oneOf(['success', 'warning', 'error', 'info', 'critical']),
  onClose: pt.func,
  primaryAction: pt.func,
  secondaryAction: pt.func,
  config: pt.objectOf(pt.object),
  shoodBeFixByArtem: pt.bool,
}

export default GlobalPopupContent
