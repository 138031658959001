import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import viewTreeIcons from '@/constants/viewTree'
import { REQUEST_STATUSES } from '@/constants/requests'
import Loader from '@/components/blocks/Loader'

import {
Container,
ContentContainer,
Header,
MainTitle,
Row,
IconContainer,
PrimaryTitle,
Content,
} from './styles'


const CompositionNode = ({
  compositionNode,
  getCompositionNodeStatus,
}) => {
  if (getCompositionNodeStatus === REQUEST_STATUSES.PENDING) {
    return (
      <Content>
        <Loader />
      </Content>
    )
  }
  return (
    <Content>
    {compositionNode.map((element) => {
      return (
        !Object.keys(element.value).length ? 
        <Container>
          <PrimaryTitle textCenter>
            {'Нет дочерних объектов'}
          </PrimaryTitle>
          </Container>

          :
          <>
            <Container>
              <Header>
                {element.geoZoneName &&
                  <MainTitle>
                  {`Геозона: ${element.geoZoneName}`}
                </MainTitle>
                }
                {element.projectName &&
                 <MainTitle>
                  {`Проект: ${element.projectName}`}
                 </MainTitle>
                }
                {element.integrationName && 
                  <MainTitle>
                    {`Интеграция: ${element.integrationName}`}
                  </MainTitle>
                }
                {element.name &&
                 <MainTitle>
                  {`${element.name}`}
                 </MainTitle>
                }
              </Header> 
              <ContentContainer>
              {Object.keys(element.value).map((objectType) => {
              const Icon = viewTreeIcons[objectType]
              return (
                <Row>
                  <IconContainer>
                    {Icon && <Icon />}
                  </IconContainer>
                  <PrimaryTitle flex>
                  {<Lang id={`globalNames.objectTypes.${objectType}`} />}
                    :
                  </PrimaryTitle>
                  <PrimaryTitle status={element} value>
                    {element.value[objectType]}
                  </PrimaryTitle>
                </Row>
              )
              })
              }
              </ContentContainer>
            </Container>
          </>
      )
    })}
    </Content>
  )
   
}

CompositionNode.propTypes = {
 
}

CompositionNode.defaultProps = {
  compositionNode: [],
}
export default CompositionNode
