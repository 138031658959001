import React, { useMemo } from 'react'
import pt from 'prop-types'
import {
  CHAR_PER_LINE_IN_TOOLTIP_CLUSTER_TOOLTIP,
  REVERSE_ICON_TO_TYPE,
  TITLE_TO_TOOLTIP_STATUS,
} from '@/constants/maps'
import {
  STATUS_COLORS,
} from '@/constants/styles/mapsMarks'
import {
  OK, WARNING, ERROR, INFO,
} from '@/constants/objectStatuses'
import {
  Container, Tip, ElementContainer,
  ElementTitle,
} from './styles'

const typeToOffset = {
  small: 20,
  medium: 30,
  big: 40,
}

const ToolTip = ({
  data, theme, type, name, icon, isMonitoring,
}) => {
  const titleLines = useMemo(
    () => Math.ceil(name.length / CHAR_PER_LINE_IN_TOOLTIP_CLUSTER_TOOLTIP),
    [name],
  )
  const count = useMemo(
    () => Object.keys(data).filter((element) => data[element] > 0).length + 1, [data],
  )
  return (
    <>
      <Container
        theme={theme}
        type={type}
        count={count}
        titleLines={titleLines}
        isMonitoring={isMonitoring}
      >
        {Object.keys(data).map((element) => {
          const Icon = REVERSE_ICON_TO_TYPE[element]
          return (
            <ElementContainer key={element}>
              {Icon && (<Icon color={STATUS_COLORS[element]} />)}
              {REVERSE_ICON_TO_TYPE[element.type]}
              <ElementTitle theme={theme} type={element}>
                {`${data[element]} ${TITLE_TO_TOOLTIP_STATUS[element]}`}
              </ElementTitle>
            </ElementContainer>
          )
        })}
      </Container>
      <Tip theme={theme} type={type} offset={typeToOffset[type] || 0} />
    </>
  )
}

ToolTip.defaultProps = {
  data: {
    [OK]: 0,
    [WARNING]: 0,
    [ERROR]: 0,
    [INFO]: 0,
  },
  type: 'small',
  name: '',
  icon: null,
}

ToolTip.propTypes = {
  theme: pt.objectOf(pt.object).isRequired,
  name: pt.string,
  type: pt.string,
  icon: pt.element,
  data: pt.shape({
    [OK]: pt.number,
    [WARNING]: pt.number,
    [ERROR]: pt.number,
    [INFO]: pt.number,
  }),
}


export default ToolTip
